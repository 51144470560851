import './App.css';

function App() {
  window.location.replace("https://instagram.com/wyteco");
  return (
    <div className="App">
    </div>
  );
}

export default App;
